import gsap from "gsap";

export function spinReel() {
    const sectors = [
        '€ 2100',
        'Попробуй снова',
        '380 FS',
        'Без бонуса',
        '€ 1000',
        '€ 100',
        '10 FS',
        '100 FS',
    ];

  const spins = [2, 0]; // Индексы секторов, на которых должно остановиться колесо
  let currentSpin = 0;
  let currentRotation = -22; // Отслеживание текущего угла вращения

  const wheel = document.querySelector(".wheel__sectors");
  const spinButtons = document.querySelectorAll(".spin");

  spinButtons.forEach((button) => {
    button.addEventListener("click", () => {
      if (
        currentSpin < spins.length &&
        !button.classList.contains("disabled")
      ) {
        // Блокируем кнопку для повторного нажатия
        spinButtons.forEach((btn) => btn.classList.add("disabled"));

        const targetSector = spins[currentSpin];
        const sectorAngle = 360 / sectors.length;

        // Рассчитываем целевое значение вращения
        const targetRotation = (360 - targetSector * sectorAngle) % 360;
        const fullSpins = 4;
        const totalRotation =
          fullSpins * 360 + targetRotation - (currentRotation % 360) + 5;

        gsap.to(wheel, {
          rotation: `+=${totalRotation}`,
          duration: 7,
          ease: "power4.out",
          onStart: () => {
            if (!currentSpin) {
              gtag("event", "spin_first_click");
            } else if (currentSpin) {
              gtag("event", "spin_second_click");
            }
          },
          onComplete: () => {
            if (!currentSpin) {
              gtag("event", "popup_freespin");
            } else if (currentSpin) {
              gtag("event", "popup_money");
            }
            currentRotation += totalRotation;
            currentRotation = (currentRotation + 360) % 360;

            // Сохраняем текущий спин в localStorage
            localStorage.setItem(
              "currentSpin",
              currentSpin === 0 ? "first" : "second"
            );
            currentSpin++;

            // Разблокируем кнопки после завершения вращения
            spinButtons.forEach((btn) => btn.classList.remove("disabled"));
          },
        });
      } else if (button.classList.contains("disabled")) {
        console.log("Spin in progress...");
      } else {
        console.log("No more spins available");
      }
    });
  });
}
